import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

const CustomDatePicker = ({ name, date, onChange, min, max, disabled }) => {
  return (
    <DatePicker
      name={name}
      className="outline-none bg-transparent"
      selected={date}
      onChange={(newDate) => onChange(newDate)}
      dateFormat="MMM d, yyyy"
      minDate={min}
      maxDate={max}
      disabled={disabled}
    />
  );
};

export default CustomDatePicker;
