import React from "react";

const ToolBar = ({ onDelete }) => {
  const [areOptionsOpen, openOptions] = React.useState(false);
  return (
    <div
      onClick={() => openOptions(!areOptionsOpen)}
      className="rtl relative block space-y-2 cursor-pointer text-2xl md:text-5xl text-text-color/50"
    >
      ...
      {areOptionsOpen && (
        <button
          onClick={onDelete}
          className="absolute cursor-pointer w-20 md:w-32 h-6 md:h-11 flex items-center justify-evenly border rounded shadow-[4px 4px 16px -1px rgba(0, 0, 0, 0.08)] px-3 py-1"
        >
          <span className="text-text-color text-xs md:text-xl font-normal ml-2">
            delete
          </span>
          <img
            src="/assets/svg/trash.svg"
            alt="delete-task"
            className="w-3 md:w-5 lg:w-6 h-16 md:h-20 lg:h-28"
          />
        </button>
      )}
    </div>
  );
};

export default ToolBar;
