import { configureStore } from "@reduxjs/toolkit";
import users from "./slices/users";
import departments from "./slices/departments";
import projects from "./slices/projects";
import tasks from "./slices/tasks";

export const store = configureStore({
  reducer: {
    users,
    departments,
    projects,
    tasks,
  },
  devTools: process.env.NODE_ENV !== "production",
});
