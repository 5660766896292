import colors from "../data/colors.json";

export function generateLightColor(str) {
  const color = colors.find((item) => item.text === str)?.color;
  if (color) return color;
  // if the `str` isn't in `colors` we try to generate the color
  const hash = str
    ?.split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);

  const hue = ((hash % 360) + 360) % 360; // Ensure hue is within [0, 360)
  const saturation = 43; // You can adjust this value as needed
  const lightness = 82; // You can adjust this value as needed

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}
