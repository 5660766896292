import React from "react";
import AppTable, {
  AppTableBody,
  AppTableColumn,
  AppTableHead,
  AppTableTitle,
} from "./AppTable";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import priorities from "../data/priority.json";
import statuses from "../data/status.json";
import AddTask from "./AddTask";
import CustomSelect, { CustomOption } from "./CustomSelect";
import CustomDatePicker from "./CustomDatePicker";
import { doc, updateDoc } from "firebase/firestore";
import { database } from "../firebase";

const TasksTable = ({
  department,
  tasks,
  projects,
  defaults: { projectId } = {},
}) => {
  const navigate = useNavigate();
  const { me, users } = useSelector((state) => {
    return {
      me: state.users.me,
      users: state.users.users,
    };
  });
  return (
    <>
      <AppTable>
        <AppTableHead>
          <AppTableTitle
            title="Action Item"
            className="text-[#363636] opacity-50"
          />
          <AppTableTitle
            icon="/assets/svg/dropdown.svg"
            title="Priority"
            className="text-[#363636] text-opacity-50"
          />
          {!projectId && (
            <AppTableTitle
              icon="/assets/svg/north_east.svg"
              title="Project"
              className="text-[#363636] text-opacity-50"
            />
          )}
          <AppTableTitle
            icon="/assets/svg/receive_squared.svg"
            title="Do Date"
            className="text-[#363636] text-opacity-50"
          />
          <AppTableTitle
            icon="/assets/svg/receive_squared.svg"
            title="Due Date"
            className="text-[#363636] text-opacity-50"
          />
          <AppTableTitle
            icon="/assets/svg/dropdown.svg"
            title="Status"
            className="text-[#363636] text-opacity-50"
          />
          <AppTableTitle
            icon="/assets/svg/group.svg"
            title="Team"
            className="text-[#363636] text-opacity-50"
          />
          <AppTableTitle
            icon="/assets/svg/menu.svg"
            title="Note"
            className="text-[#363636] text-opacity-50"
          />
        </AppTableHead>
        <AppTableBody>
          {tasks?.map((task, index) => {
            const taskProject = projects?.find(
              (project) => project?.id === task.project
            );
            const onUpdateTask = (updates) => {
              updateDoc(doc(database, "tasks", task?.id), updates);
            };
            return (
              <tr key={index}>
                <AppTableColumn>
                  <div className="relative group flex items-center justify-between">
                    <input
                      type="text"
                      className="font-semibold bg-transparent outline-none"
                      defaultValue={task?.action}
                      disabled={!task?.team?.includes(me?.uid)}
                      onBlur={(event) => {
                        if (event.target.value !== task?.action) {
                          onUpdateTask({ action: event.target.value });
                        }
                      }}
                    />
                    <button
                      onClick={() => navigate(`/task/${task.id}`)}
                      className="opacity-0 group-hover:opacity-100 duration-300 absolute top-0 right-0 flex items-center justify-center border border-[rgba(54, 54, 54, 0.38)] px-1 py-1 bg-[#FEFEFE] rounded text-text-color text-[10px] md:text-sm font-normal"
                    >
                      <img
                        src="/assets/svg/open_icon.svg"
                        alt="open"
                        className="mr-1"
                      />
                      open
                    </button>
                  </div>
                </AppTableColumn>
                <AppTableColumn>
                  <CustomSelect
                    id="priority"
                    className="px-5"
                    onSelect={(priority) => onUpdateTask({ priority })}
                  >
                    {priorities?.map((priority, index) => (
                      <CustomOption
                        key={index}
                        value={priority?.text}
                        selected={priority.text === task?.priority}
                      >
                        {priority.text}
                      </CustomOption>
                    ))}
                  </CustomSelect>
                </AppTableColumn>
                {!projectId && (
                  <AppTableColumn>
                    <div className="flex items-center gap-x-2">
                      <img src="/assets/svg/tag.svg" alt="tag" />
                      <CustomSelect
                        id="project"
                        className="px-5 font-semibold bg-transparent outline-none text-right"
                        onSelect={(project) => onUpdateTask({ project })}
                      >
                        {projects?.map((project, index) => (
                          <CustomOption
                            key={index}
                            value={project?.id}
                            selected={project?.id === task?.project}
                            disableColor
                            className="font-semibold bg-transparent outline-none"
                          >
                            {project?.name}
                          </CustomOption>
                        ))}
                      </CustomSelect>
                    </div>
                  </AppTableColumn>
                )}
                <AppTableColumn>
                  <CustomDatePicker
                    date={new Date(task?.do_date)}
                    onChange={(do_date) => {
                      if (new Date(task?.do_date) !== do_date) {
                        onUpdateTask({ do_date });
                      }
                    }}
                    max={new Date(task?.due_date)}
                    disabled={!task?.team?.includes(me?.uid)}
                  />
                </AppTableColumn>
                <AppTableColumn>
                  <CustomDatePicker
                    date={new Date(task?.due_date)}
                    onChange={(due_date) => {
                      if (new Date(task?.due_date) !== due_date)
                        onUpdateTask({ due_date });
                    }}
                    min={new Date(task?.do_date)}
                    disabled={!task?.team?.includes(me?.uid)}
                  />
                </AppTableColumn>
                <AppTableColumn>
                  <CustomSelect
                    id="status"
                    className="px-5"
                    onSelect={(status) => onUpdateTask({ status })}
                  >
                    {statuses?.map((status, index) => (
                      <CustomOption
                        key={index}
                        value={status?.text}
                        selected={status.text === task?.status}
                      >
                        {status.text}
                      </CustomOption>
                    ))}
                  </CustomSelect>
                </AppTableColumn>
                <AppTableColumn>
                  <CustomSelect
                    id="team"
                    className="px-5"
                    multiselection
                    onSelect={(user) => {
                      if (task?.status !== "done") {
                        onUpdateTask({
                          team: Array.isArray(task?.team)
                            ? task?.team?.concat(user)
                            : [user],
                        });
                      }
                    }}
                    onUnSelect={(uid) => {
                      if (task?.status !== "done") {
                        const team = task?.team.filter(
                          (memberId) => memberId !== uid
                        );
                        if (team?.length > 0) {
                          onUpdateTask({
                            team,
                          });
                        }
                      }
                    }}
                  >
                    {users
                      ?.filter(
                        (user) =>
                          !taskProject || taskProject?.team?.includes(user.uid)
                      )
                      .map((user, index) => (
                        <CustomOption
                          key={index}
                          value={user?.uid}
                          selected={task?.team?.includes(user?.uid)}
                        >
                          {user.customClaims?.job_title}
                        </CustomOption>
                      ))}
                  </CustomSelect>
                </AppTableColumn>
                <AppTableColumn>
                  <textarea
                    className="text-right w-full h-auto resize-none overflow-hidden bg-transparent outline-none"
                    defaultValue={task?.note}
                    disabled={!task?.team?.includes(me?.uid)}
                    onBlur={(event) => {
                      if (event.target.value !== task?.note)
                        onUpdateTask({ note: event.target.value });
                    }}
                  />
                </AppTableColumn>
              </tr>
            );
          })}
        </AppTableBody>
      </AppTable>
      {department && (
        <AddTask department={department} uid={me?.uid} projectId={projectId} />
      )}
    </>
  );
};
export default TasksTable;
