import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  projects: [],
};
const projects = createSlice({
  name: "projects",
  initialState,
  reducers: {
    setProject: (state, action) => {
      const index = state.projects.findIndex(
        (project) => project?.id === action.payload?.id
      );
      if (index < 0) state.projects.push(action.payload);
      else state.projects[index] = { ...action.payload };
    },
    deleteProject: (state, action) => {
      state.projects = state.projects.filter(
        (project) => project?.id !== action.payload
      );
    },
  },
});

export const { deleteProject, setProject } = projects.actions;
export default projects.reducer;
