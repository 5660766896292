import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tasks: [],
};

const tasks = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    setTask: (state, action) => {
      const index = state.tasks.findIndex(
        (task) => task.id === action.payload?.id
      );
      if (index < 0) state.tasks.push(action.payload);
      else state.tasks[index] = action.payload;
    },
    deleteTask: (state, action) => {
      state.tasks = state.tasks.filter((task) => task?.id !== action.payload);
    },
    setTasks: (state, action) => {
      if (!action.payload) return;
      for (const task of action.payload) {
        const found = state.tasks.find(({ id }) => id === task.id);
        if (!found) state.tasks.push(task);
      }
    },
  },
});
export const { setTask, deleteTask, setTasks } = tasks.actions;
export default tasks.reducer;
