import React from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";

export default function AuthWithGoogleButton() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const onClick = () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(() => navigate("/"))
      .then(() => setLoading(false))
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <button
      className="w-full flex items-center justify-center gap-x-5 p-3 bg-white border-[1px] border-text-color border-opacity-40 rounded-lg"
      onClick={onClick}
      disabled={loading}
    >
      <img
        className="w-5 lg:w-8"
        src="/assets/svg/google.svg"
        alt="google-letter-logo"
      />
      <span className="text-md md:text-base lg:text-xl">
        {loading ? "loading ..." : "Continue With Google"}
      </span>
    </button>
  );
}
