import React from "react";
import colors from "../data/colors.json";
import ProgressBar from "./ProgressBar";
import { Link } from "react-router-dom";

const Project = ({ project }) => {
  return (
    <Link to={`/project/${project?.id}`}>
      <div className="relative h-48 max-h-60 w-72 flex flex-col mb-6 rounded-md overflow-hidden shadow-lg cursor-pointer transition-all duration-200 hover:scale-105">
        <div className="h-fit bg-[#363636] bg-opacity-10">
          {project?.asset && (
            <img
              src={project?.asset}
              alt="project-cover"
              className="w-full bg-no-repeat"
            />
          )}
        </div>
        <div className="absolute bottom-0 w-full h-20 p-4 space-y-2 bg-white z-10">
          <div className="flex items-center gap-x-1">
            <img src="/assets/svg/tag.svg" alt="tag" />
            <p className="font-semibold">{project?.name}</p>
          </div>
          <ProgressBar
            percentage={parseInt(
              (project?.completed * 100) / project?.total
                ? (project?.completed * 100) / project?.total
                : 0
            )}
          />
        </div>
      </div>
    </Link>
  );
};
export default function ProjectsSection({ title, projects }) {
  const { color } = colors.find((tag) => tag.text === title);
  return (
    <section className="space-y-10">
      <div className="space-y-1">
        <div className="px-6 py-2 bg-[#363636] bg-opacity-5 flex items-center gap-x-4">
          <div
            style={{ backgroundColor: color }}
            className="w-4 h-4 rotate-45"
          ></div>
          <h2 className="font-bold capitalize">{title} projects</h2>
        </div>
        <div style={{ backgroundColor: color }} className="h-1 w-fill"></div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {projects?.map((project, index) => (
          <Project key={index} project={project} />
        ))}
      </div>
    </section>
  );
}
