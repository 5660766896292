import React from "react";

const Section = ({ children, icon, title }) => {
  return (
    <section className="space-y-5 md:space-y-7 lg:space-y-10">
      <div className="flex items-center gap-x-5">
        <img src={icon} alt="section-icon" className="w-5 md:w-7 lg:w-9" />
        <h2 className="font-semibold text-lg md:text-xl lg:text-2xl">
          {title}
        </h2>
      </div>
      <div>{children}</div>
    </section>
  );
};
export default Section;
