import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import statuses from "../data/status.json";
import priorities from "../data/priority.json";
import NavigationBar from "../components/NavigationBar";
import CustomSelect, { CustomOption } from "../components/CustomSelect";
import CustomDatePicker from "../components/CustomDatePicker";
import { database } from "../firebase";
import { deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { setTask } from "../redux/slices/tasks";
import ToolBar from "../components/ToolBar";

export default function Task() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { me, users, task, projects, department } = useSelector((state) => {
    const task = state.tasks.tasks.find((task) => task?.id === id);
    return {
      me: state.users.me,
      users: state.users.users,
      projects: state.projects.projects,
      department: state.departments.departments?.find(
        (department) => department?.id === task?.department
      ),
      task,
    };
  });
  const navigate = useNavigate();
  const onUpdateTask = (updates) => {
    updateDoc(doc(database, "tasks", task?.id), updates);
  };

  const onDeleteTask = () => {
    deleteDoc(doc(database, "tasks", task?.id)).then(() =>
      navigate(`/department/${department?.id}`)
    );
  };

  React.useEffect(() => {
    const stopTaskSnapshot = onSnapshot(doc(database, "tasks", id), (doc) => {
      const task = { id: doc.id, ...doc.data() };
      task.do_date = task?.do_date?.toDate().getTime();
      task.due_date = task?.due_date?.toDate().getTime();
      dispatch(setTask(task));
    });
    return () => stopTaskSnapshot();
  }, [dispatch, id]);
  return (
    <main className="flex flex-col pb-4 md:pb-10 overflow-hidden">
      <NavigationBar>
        <Link
          to={`/department/${department?.id}`}
          className="font-normal text-[8px] md:text-xl text-text-color"
        >
          {department?.name}
        </Link>
        <Link
          to={`/task/${task?.id}`}
          className="font-normal text-[8px] md:text-xl text-text-color"
        >
          {task?.action}
        </Link>
      </NavigationBar>
      <section className="z-10 flex-1 flex-row-reverse w-full max-w-[100rem] mx-auto px-5 md:px-10 lg:px-14 space-y-10 md:space-y-20 lg:space-y-32">
        <div className="flex items-center justify-between mt-12 md:mt-28 space-x-5">
          <input
            type="text"
            className="w-full h-full font-bold text-xl md:text-2xl lg:text-3xl"
            defaultValue={task?.action}
            disabled={!task?.team?.includes(me?.uid)}
            onBlur={(event) => {
              if (event.target.value !== task?.action) {
                onUpdateTask({ action: event.target.value });
              }
            }}
          />
          <ToolBar
            onDelete={() => {
              if (task?.team?.includes(me?.uid)) {
                onDeleteTask(task?.id);
              }
            }}
          />
        </div>
        <div className="w-full md:w-1/2 space-y-5 md:space-y-10">
          <div className="grid grid-cols-2">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/dropdown.svg"
                alt="status"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Status</h2>
            </div>
            <CustomSelect
              id="status"
              onSelect={(status) => {
                if (task?.team?.includes(me?.uid)) {
                  onUpdateTask({ status });
                }
              }}
            >
              {statuses?.map(({ text: status }, index) => (
                <CustomOption
                  key={index}
                  value={status}
                  selected={status === task?.status}
                >
                  {status}
                </CustomOption>
              ))}
            </CustomSelect>
          </div>
          <div className="grid grid-cols-2 md:gap-9">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/receive_squared.svg"
                alt="do date"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Do Date</h2>
            </div>
            <CustomDatePicker
              date={task?.do_date ? new Date(task.do_date) : null}
              onChange={(do_date) => {
                if (new Date(task?.do_date) !== do_date)
                  onUpdateTask({ do_date });
              }}
              max={new Date(task?.due_date)}
              disabled={!task?.team?.includes(me?.uid)}
            />
          </div>
          <div className="grid grid-cols-2">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/dropdown.svg"
                alt="priority"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Priority</h2>
            </div>
            <CustomSelect
              id="priority"
              onSelect={(priority) => {
                if (task?.team?.includes(me?.uid)) {
                  onUpdateTask({ priority });
                }
              }}
            >
              {priorities?.map(({ text: priority }, index) => (
                <CustomOption
                  key={index}
                  value={priority}
                  selected={priority === task?.priority}
                >
                  {priority}
                </CustomOption>
              ))}
            </CustomSelect>
          </div>
          <div className="grid grid-cols-2 md:gap-9">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/menu.svg"
                alt="comments"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Comments</h2>
            </div>
            <textarea
              className="h-auto resize-none overflow-hidden"
              placeholder="Empty"
              defaultValue={task?.note}
              disabled={!task?.team?.includes(me?.uid)}
              onBlur={(event) => {
                if (event.target.value !== task?.note)
                  onUpdateTask({ note: event.target.value });
              }}
            />
          </div>
          <div className="grid grid-cols-2 md:gap-9">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/receive_squared.svg"
                alt="due date"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Due Date</h2>
            </div>
            <CustomDatePicker
              date={task?.due_date ? new Date(task?.due_date) : null}
              onChange={(due_date) => {
                if (new Date(task?.due_date) !== due_date)
                  onUpdateTask({ due_date });
              }}
              min={new Date(task?.do_date)}
              disabled={!task?.team?.includes(me?.uid)}
            />
          </div>
          <div className="grid grid-cols-2">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/north_east.svg"
                alt="done"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Project</h2>
            </div>
            <CustomSelect
              id="project"
              className="font-semibold bg-transparent outline-none text-right"
              onSelect={(project) => {
                if (task?.team?.includes(me?.uid)) {
                  onUpdateTask({ project });
                }
              }}
            >
              {projects
                ?.filter((project) => project?.department === department?.id)
                .map((project, index) => (
                  <CustomOption
                    key={index}
                    value={project?.id}
                    selected={task?.project === project?.id}
                    disableColor
                    className="font-semibold bg-transparent outline-none"
                  >
                    {project?.name}
                  </CustomOption>
                ))}
            </CustomSelect>
          </div>
          <div className="grid grid-cols-2">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/group.svg"
                alt="team"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Team</h2>
            </div>
            <CustomSelect
              id="team"
              multiselection
              onSelect={(user) => {
                if (task?.team?.includes(me?.uid) && task?.status !== "done") {
                  onUpdateTask({
                    team: Array.isArray(task?.team)
                      ? task?.team?.concat(user)
                      : [user],
                  });
                }
              }}
              onUnSelect={(user) => {
                if (task?.team?.includes(me?.uid) && task?.status !== "done") {
                  const team = task?.team.filter((member) => member !== user);
                  if (team?.length > 0) {
                    onUpdateTask({
                      team,
                    });
                  }
                }
              }}
            >
              {users?.map((user, index) => (
                <CustomOption
                  key={index}
                  value={user?.uid}
                  selected={task?.team?.includes(user?.uid)}
                >
                  {user.customClaims?.job_title}
                </CustomOption>
              ))}
            </CustomSelect>
          </div>
        </div>
      </section>
    </main>
  );
}
