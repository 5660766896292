import React from "react";

const DepartmentHead = ({
  logo,
  name = null,
  withoutLogoContainer = false,
}) => {
  return (
    <div className="space-y-5">
      {!withoutLogoContainer ? (
        <div className="-mt-6 md:-mt-10 lg:-mt-16 w-16 md:w-20 lg:w-28 h-16 md:h-20 lg:h-28 rounded-sm border-[1px] border-gray-500 bg-white p-5 flex items-center justify-center">
          <img
            src={name ? logo : "/assets/png/logo.png"}
            alt="department-logo"
          />
        </div>
      ) : (
        <img
          src={name ? logo : "/assets/png/logo.png"}
          alt="department-logo"
          className="-mt-7 md:-mt-10 lg:-mt-16 w-16 md:w-20 lg:w-28 h-16 md:h-20 lg:h-28"
        />
      )}
      <h1 className="font-bold text-xl md:text-2xl lg:text-3xl">
        {name ? name : "Vamill Management"}
      </h1>
    </div>
  );
};
export default DepartmentHead;
