import React from "react";

export const AppTableTitle = ({ title, icon, className }) => {
  return (
    <th
      className={`text-xs md:text-sm lg:text-base font-normal p-3 ${className}`}
    >
      <div className="flex items-center gap-x-2">
        {icon && <img src={icon} alt="person-icon" className="w-5" />}
        <span>{title}</span>
      </div>
    </th>
  );
};
export const AppTableColumn = ({ children}) => {
  return (
    <td className="max-w-lg text-xs md:text-sm lg:text-base py-2 px-8 border-r-[1px] border-b-[1px] border-gray-500">
      {children}
    </td>
  );
};
export const AppTableHead = ({ children }) => {
  return (
    <thead className="border-b-[1px] border-b-solid border-b-gray-500 text-left text-lg md:text-xl">
      <tr>{children}</tr>
    </thead>
  );
};
export const AppTableBody = ({ children }) => {
  return <tbody>{children}</tbody>;
};
const AppTable = ({ children }) => {
  return (
    <div className="overflow-x-auto min-h-[20rem]">
      <table className="w-full min-w-max border-b-[1px] border-solid border-gray-500">
        {children}
      </table>
    </div>
  );
};
export default AppTable;
