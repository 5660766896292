import React from "react";
import DepartmentHead from "../components/DepartmentHead";
import Section from "../components/Section";
import Departments from "../components/Departments";
import AppTable, {
  AppTableBody,
  AppTableColumn,
  AppTableHead,
  AppTableTitle,
} from "../components/AppTable";
import Tag from "../components/Tag";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment/moment";

const Tasks = ({ ongoing, dueDate, done, nextInline }) => {
  const maxLength = Math.max(
    ongoing?.length,
    dueDate?.length,
    done?.length,
    nextInline?.length
  );
  return (
    <AppTable>
      <AppTableHead>
        <AppTableTitle title="On Going Tasks" />
        <AppTableTitle title="Due Date Tasks" />
        <AppTableTitle title="Done Tasks" />
        <AppTableTitle title="Next In Line Tasks" />
      </AppTableHead>
      <AppTableBody>
        {[...Array(maxLength)].map((_, index) => (
          <tr key={index}>
            <AppTableColumn>
              <Link to={`task/${ongoing[index]?.id}`}>
                {ongoing[index]?.action}
              </Link>
            </AppTableColumn>
            <AppTableColumn>
              <Link to={`task/${dueDate[index]?.id}`}>
                {dueDate[index]?.action}
              </Link>
            </AppTableColumn>
            <AppTableColumn>
              <Link to={`task/${done[index]?.id}`}>{done[index]?.action}</Link>
            </AppTableColumn>
            <AppTableColumn>
              <Link to={`task/${nextInline[index]?.id}`}>
                {nextInline[index]?.action}
              </Link>
            </AppTableColumn>
          </tr>
        ))}
      </AppTableBody>
    </AppTable>
  );
};

const ProfilePopup = () => {
  const me = useSelector((state) => state.users?.me);
  const [popup, setPopup] = React.useState(false);
  const navigate = useNavigate();
  return (
    <div className="absolute z-40 top-1 left-1 md:left-5 lg:left-7 md:top-5 lg:top-7">
      <button
        onClick={() => setPopup(!popup)}
        className="bg-gray-700 p-2 rounded-sm"
      >
        <img
          src="/assets/svg/2x_right_chevron.svg"
          alt="right-chevron"
          className="w-2 md:w-3"
        />
      </button>
      {popup && (
        <div
          onClick={() => navigate(`/user/${me?.uid}`)}
          className="cursor-pointer bg-white rounded-md px-3 md:px-5 py-1 md:py-2 mt-1 md:mt-2 flex items-center gap-x-4"
        >
          <img
            src={me?.photoURL}
            alt="profile"
            className="w-6 md:w-10 rounded-full"
          />
          <span className="font-bold text-xs md:text-sm lg:text-base">
            {me?.displayName}
          </span>
        </div>
      )}
    </div>
  );
};
export default function Home() {
  const { users, tasks, departments } = useSelector((state) => ({
    users: state.users.users,
    tasks: state.tasks.tasks,
    departments: state.departments.departments,
  }));
  const today = new Date();
  const ongoing = tasks
    .filter((task) => {
      if (task?.status !== "active") return false;
      const due_date = moment(new Date(task.due_date)).startOf("day");
      return due_date.isBefore(today);
    })
    .map((task) => ({
      id: task.id,
      action: task.action,
    }));

  const dueDate = tasks
    .filter((task) => {
      if (task?.status === "waiting") return false;
      const due_date = moment(new Date(task.due_date)).startOf("day");
      return due_date.isSameOrAfter(today);
    })
    .map((task) => ({
      id: task.id,
      action: task.action,
    }));

  const done = tasks
    .filter((task) => {
      if(task.status !== "done") return false;
      const done_week = moment(new Date(task.due_date)).startOf("week");
      return done_week.isSameOrAfter(moment(today).startOf('week'));
    })
    .map((task) => ({
      id: task.id,
      action: task.action,
    }));

  const nextInline = tasks
    .filter((task) => task.status === "waiting")
    .map((task) => ({
      id: task.id,
      action: task.action,
    }));
  return (
    <main className="flex flex-col pb-10">
      <div className="relative w-full">
        <img
          src="/assets/png/covers/home.png"
          alt="home-cover"
          className="w-full h-24 md:h-fit"
        />
        <ProfilePopup />
      </div>
      <section className="z-10 flex-1 w-full max-w-[100rem] mx-auto px-5 md:px-10 lg:px-14 space-y-10 md:space-y-20 lg:space-y-32">
        <DepartmentHead />
        <Section icon="/assets/svg/data_display.svg" title="Data Display">
          <div className="max-h-96 overflow-y-scroll">
            <Tasks
              ongoing={ongoing}
              dueDate={dueDate}
              done={done}
              nextInline={nextInline}
            />
          </div>
        </Section>
        <Section icon="/assets/svg/departments.svg" title="Departments">
          <Departments departments={departments} />
        </Section>
        <Section icon="/assets/svg/team.svg" title="Team Status">
          <div className="max-h-96 overflow-y-scroll">
            <AppTable>
              <AppTableHead>
                <AppTableTitle
                  title="Hero Name"
                  icon="/assets/svg/person.svg"
                  className="w-[30%]"
                />
                <AppTableTitle title="Status" icon="/assets/svg/dropdown.svg" />
                <AppTableTitle
                  title="Tasks"
                  icon="/assets/svg/menu.svg"
                  className="w-1/2"
                />
              </AppTableHead>
              <AppTableBody>
                {users?.map((user, index) => (
                  <tr key={index}>
                    <AppTableColumn>
                      <Link to={`/user/${user?.uid}`}>
                        <p className="font-semibold">
                          {user?.customClaims?.job_title}
                        </p>
                      </Link>
                    </AppTableColumn>
                    <AppTableColumn>
                      <Tag name={user?.customClaims?.status} />
                    </AppTableColumn>
                    <AppTableColumn>
                      <div className="flex gap-2 flex-wrap">
                        {tasks
                          .filter(
                            (task) =>
                              task?.status !== "done" &&
                              task?.team?.includes(user?.uid)
                          )
                          .map((task, index) => (
                            <Tag key={index} name={task?.action} randomColor />
                          ))}
                      </div>
                    </AppTableColumn>
                  </tr>
                ))}
              </AppTableBody>
            </AppTable>
          </div>
        </Section>
      </section>
    </main>
  );
}
