import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

/**
 * children
 *
 */
const NavigationBar = ({ children }) => {
  const user = useSelector((state) => {
    return state.users?.users?.find((user) => user?.uid === state.users?.me?.uid);
  });
  const navigate = useNavigate();
  return (
    <div className="fixed top-0 left-0 w-full z-50 flex items-center justify-between px-4 md:px-12 h-10 md:h-16 overflow-hidden bg-[#FCFCFC] shadow-sm">
      <div className="flex items-center justify-start space-x-2 md:space-x-4">
        <img
          src="/assets/png/logo.png"
          alt="vamill-logo"
          className="h-2 md:h-4"
        />
        <Link
          to="/"
          className="font-normal text-[8px] md:text-xl text-text-color"
        >
          الصفحة الرئيسية
        </Link>
        <span className="font-normal text-[8px] md:text-xl text-text-color">
          /
        </span>
        {React.Children.toArray(children).map((child, index) => (
          <div key={index}>
            {React.cloneElement(child)}
            {index < React.Children.toArray(children).length - 1 && (
              <span className="font-normal text-[8px] md:text-xl text-text-color">
                /
              </span>
            )}
          </div>
        ))}
      </div>
      <div
        onClick={() => navigate(`/user/${user?.uid}`)}
        className="cursor-pointer flex items-center space-x-2 md:space-x-4"
      >
        <span className="font-bold text-[8px] md:text-sm lg:text-base">
          {user?.customClaims?.job_title}
        </span>
        <img
          src={user?.photoURL}
          alt="profile"
          className="w-6 md:w-10 rounded-full"
        />
      </div>
    </div>
  );
};

export default NavigationBar;
