import React from "react";
import { generateLightColor } from "../core/colors";

export const CustomOption = ({
  value,
  selected,
  disableColor = false,
  children,
  onSelect = () => {},
  onUnSelect = () => {},
}) => {
  const color = !disableColor ? generateLightColor(value) : "";
  function onSelectClick(event) {
    event.stopPropagation();
    if (!selected) onSelect(value);
  }
  function onUnSelectClick() {
    onUnSelect(value);
  }
  return (
    <div
      onClick={onSelectClick}
      style={{
        backgroundColor: color,
      }}
      className="outline-none px-1 md:px-4 py-1 rounded-sm w-fit h-6 flex items-center gap-x-2 cursor-pointer"
    >
      {children}
      {selected && (
        <button type="button" onClick={onUnSelectClick}>
          <img src="/assets/svg/close.svg" alt="close" className="w-3 h-3" />
        </button>
      )}
    </div>
  );
};
export default function CustomSelect({
  id,
  className,
  children,
  disabled = false,
  multiselection = false,
  onSelect = () => {},
  onUnSelect = () => {},
}) {
  const menu = React.useRef();
  const [isDropped, setDropped] = React.useState(false);
  const selectedChildren = React.Children.toArray(children).filter(
    (child) => child.props.selected
  );
  // once the user clicks outside the menu box the menu will auto close.
  React.useEffect(() => {
    const onClickOutside = (event) => {
      if (!menu.current) return;
      // checks whether the clicked target is outside menu.
      if (!menu.current?.contains(event?.target)) setDropped(false);
    };
    document.addEventListener("mousedown", onClickOutside);
    return () => document.removeEventListener("mousedown", onClickOutside);
  }, [menu]);
  return (
    <div
      id={id}
      className={`${className ?? ""} relative w-full min-w-[10px] ${
        selectedChildren.length > 0 ? "h-auto" : "h-10"
      } ${!disabled && "cursor-text"} flex items-center flex-wrap gap-y-1`}
      onClick={() => !disabled && setDropped(true)}
    >
      {selectedChildren.map((child) =>
        React.cloneElement(child, {
          selected: false,
        })
      )}
      {!disabled && isDropped && (
        <div
          ref={menu}
          className="absolute overflow-y-scroll top-0 -left-5 z-10 w-72 max-h-72 h-fit rounded-md shadow-[-1px_3px_9px_-0px_rgba(0,0,0,0.75)] bg-white cursor-auto"
        >
          <div className="bg-[#363636] bg-opacity-5 px-6 py-2 space-y-1">
            {selectedChildren.map((child) =>
              React.cloneElement(child, {
                onUnSelect: (value) => {
                  onUnSelect(value);
                  if (!multiselection) setDropped(false);
                },
              })
            )}
          </div>
          <div className="px-6 py-3 space-y-2">
            <p className="text-[#363636] text-opacity-30">Select an option</p>
            {React.Children.map(children, (child) => {
              if (React.isValidElement(child))
                return React.cloneElement(child, {
                  onSelect: (value) => {
                    onSelect(value);
                    if (!multiselection) setDropped(false);
                  },
                });
              return child;
            })}
          </div>
        </div>
      )}
    </div>
  );
}
