import React from "react";
import AuthWithGoogleButton from "../components/AuthWithGoogle";

export default function Auth() {
  return (
    <main className="w-screen h-screen flex items-center justify-center">
      <div className="flex flex-col items-center gap-y-14">
        <img src="/assets/png/logo.png" alt="vm-logo" className="w-24" />
        <h1 className="capitalize font-bold text-xl lg:text-2xl text-center">
          welcome to vamill management
        </h1>
        <div className="w-3/4 lg:w-full m-auto">
          <AuthWithGoogleButton />
        </div>
      </div>
    </main>
  );
}
