import React from "react";
import { addDoc, collection } from "firebase/firestore";
import { database } from "../firebase";

const AddTask = ({ department, uid, projectId }) => {
  /**
   * task parameter is an object contain the property to override.
   * the rest of the properties will be set up as the default added value.
   *  @param overrides
   */
  const onCreateTask = (overrides) => {
    const task = {
      action: "",
      department: department?.id,
      project: projectId ?? "",
      do_date: new Date(Date.now()),
      due_date: new Date(Date.now()),
      status: "active",
      priority: "1st Priority",
      note: "",
      team: [uid],
      ...overrides,
    };
    addDoc(collection(database, "tasks"), task);
  };
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        const action = new FormData(event.currentTarget)
          .get("action")
          ?.toString();
        if (action.trim().length > 0) {
          onCreateTask({
            action,
          });
        }
        event.currentTarget?.reset();
      }}
      className="md:ml-5 md:mb-2"
    >
      <input
        required
        type="text"
        name="action"
        placeholder="+ New Task"
        className="outline-none p-2 w-full"
      />
    </form>
  );
};
export default AddTask;
