import React, { useEffect } from "react";
import { auth, database } from "../firebase";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, setMe } from "../redux/slices/users";
import { collection, onSnapshot } from "firebase/firestore";
import { deleteTask, setTask } from "../redux/slices/tasks";
import { setDepartment } from "../redux/slices/departments";

/**
 *  It does check for the user authentication status if it's expired then
 *  it redirects the user the authentication page, otherwise it sets the user object to the user state.
 */
export default function AuthenticationGuard({ children }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.users?.me);
  // Fetch the user info from firebase if the tokens are valid
  useEffect(() => {
    if (window.location.pathname !== "/auth") {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdToken();
          dispatch(setMe({ token, ...user.toJSON() }));
        } else window.location.pathname = "/auth";
      });
    }
  }, [dispatch]);
  // fetch all users, tasks, departments info.
  useEffect(() => {
    if (!me) return;
    dispatch(fetchUsers());
  }, [dispatch, me]);
  useEffect(() => {
    const stopTasksSnapshot = onSnapshot(
      collection(database, "tasks"),
      (query) => {
        query.docChanges().forEach((change) => {
          if (change.type === "removed") {
            dispatch(deleteTask(change.doc.id));
          } else {
            let task = { id: change.doc.id, ...change.doc.data() };
            task = {
              ...task,
              do_date: task.do_date?.toDate()?.getTime(),
              due_date: task.due_date?.toDate()?.getTime(),
            };
            dispatch(setTask(task));
          }
        });
      }
    );
    const stopDepartmentsSnapshot = onSnapshot(
      collection(database, "departments"),
      (query) => {
        query.docChanges().forEach((change) => {
          if (change.type !== "removed") {
            dispatch(
              setDepartment({ id: change.doc.id, ...change.doc.data() })
            );
          }
        });
      }
    );
    return () => {
      stopTasksSnapshot();
      stopDepartmentsSnapshot();
    };
  }, [dispatch]);
  return <>{children}</>;
}
