import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Tag from "../components/Tag";
import Section from "../components/Section";
import AppTable, {
  AppTableBody,
  AppTableColumn,
  AppTableHead,
  AppTableTitle,
} from "../components/AppTable";
import NavigationBar from "../components/NavigationBar";
import { editUser } from "../redux/slices/users";
import statuses from "../data/status.json";
import CustomSelect, { CustomOption } from "../components/CustomSelect";

export default function User() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { me, user, tasks } = useSelector((state) => ({
    me: state.users.me,
    user: state.users.users?.find((user) => user?.uid === id),
    tasks: state.tasks.tasks,
  }));
  return (
    <main className="flex flex-col pb-10">
      <NavigationBar>
        <Link
          to={`/user/${user?.uid}`}
          className="font-normal text-[8px] md:text-xl text-text-color"
        >
          {user?.customClaims?.job_title}
        </Link>
      </NavigationBar>
      <div className="relative w-full">
        <img
          src="/assets/png/covers/user.png"
          alt="home-cover"
          className="w-full"
        />
      </div>
      <section className="z-10 flex-1 w-full max-w-[100rem] mx-auto px-5 py-10 md:px-10 md:py-20 lg:px-14 lg:py-24 space-y-10 md:space-y-20 lg:space-y-32">
        <div className="space-y-5">
          <img
            src={user?.photoURL}
            alt="google-profile"
            className="w-16 md:w-20 lg:w-28 rounded-full"
          />
          <div className="space-y-2">
            <input
              disabled={me?.uid !== user?.uid}
              type="text"
              className="font-bold text-xl md:text-2xl lg:text-3xl"
              defaultValue={user?.customClaims?.job_title}
              onBlur={(event) => {
                const job_title = event.target.value.trim();
                if (job_title.length > 0 && job_title !== user?.customClaims?.job_title) {
                  dispatch(
                    editUser({ job_title, status: user?.customClaims?.status })
                  );
                }
              }}
            />
            <div className="w-fit">
              <CustomSelect
                disabled={me?.uid !== user?.uid}
                id="status"
                className="pr-5"
                onSelect={(status) => {
                  dispatch(editUser({ job_title: user?.displayName, status }));
                }}
              >
                {statuses?.map((status, index) => (
                  <CustomOption
                    key={index}
                    value={status?.text}
                    selected={status.text === user?.customClaims?.status}
                  >
                    {status.text}
                  </CustomOption>
                ))}
              </CustomSelect>
            </div>
          </div>
        </div>
        <Section icon="/assets/svg/smarthome.svg" title="My Profile">
          <div className="space-y-3 md:space-y-4 lg:space-y-5 ml-10 w-1/2 md:w-1/3 text-sm md:text-base lg:text-lg font-semibold">
            <div className="w-full flex items-center justify-between">
              <h2>Tasks Done</h2>
              <h2 className="text-[#E32434]">{user?.customClaims?.tasks}</h2>
            </div>
            <div className="w-full flex items-center justify-between">
              <h2>Projects Done</h2>
              <h2 className="text-[#E32434]">{user?.customClaims?.projects}</h2>
            </div>
          </div>
        </Section>
        <Section icon="/assets/svg/group.svg" title="Tasks Table">
          <AppTable>
            <AppTableHead>
              <AppTableTitle
                title="Action Item"
                className="text-[#363636] opacity-50"
              />
              <AppTableTitle
                icon="/assets/svg/dropdown.svg"
                title="Priority"
                className="text-[#363636] text-opacity-50"
              />
              <AppTableTitle
                icon="/assets/svg/dropdown.svg"
                title="Status"
                className="text-[#363636] text-opacity-50"
              />
              <AppTableTitle
                icon="/assets/svg/receive_squared.svg"
                title="Do Date"
                className="text-[#363636] text-opacity-50"
              />
              <AppTableTitle
                icon="/assets/svg/receive_squared.svg"
                title="Due Date"
                className="text-[#363636] text-opacity-50"
              />
              <AppTableTitle
                icon="/assets/svg/menu.svg"
                title="Note"
                className="text-[#363636] text-opacity-50"
              />
            </AppTableHead>
            <AppTableBody>
              {tasks
                .filter((task) => task?.team?.includes(user?.uid))
                ?.map((task, index) => (
                  <tr key={index}>
                    <AppTableColumn>
                      <p className="font-semibold">{task?.action}</p>
                    </AppTableColumn>
                    <AppTableColumn>
                      <Tag name={task?.priority} />
                    </AppTableColumn>
                    <AppTableColumn>
                      <Tag name={task?.status} />
                    </AppTableColumn>
                    <AppTableColumn>
                      <p>{new Date(task?.do_date).toDateString()}</p>
                    </AppTableColumn>
                    <AppTableColumn>
                      <p>{new Date(task?.due_date).toDateString()}</p>
                    </AppTableColumn>
                    <AppTableColumn>
                      <p>{task?.note}</p>
                    </AppTableColumn>
                  </tr>
                ))}
            </AppTableBody>
          </AppTable>
        </Section>
      </section>
    </main>
  );
}
