import React from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DepartmentHead from "../components/DepartmentHead";
import DropDownSection from "../components/DropDownSection";
import AppTable, {
  AppTableBody,
  AppTableColumn,
  AppTableHead,
  AppTableTitle,
} from "../components/AppTable";
import Tag from "../components/Tag";
import Calendar from "../components/Calendar";
import ProgressBar from "../components/ProgressBar";
import ProjectsSection from "../components/ProjectsSection";
import TasksTable from "../components/TasksTable";
import NavigationBar from "../components/NavigationBar";
import {
  addDoc,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { database } from "../firebase";
import { deleteProject, setProject } from "../redux/slices/projects";

export default function Department() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { me, users, department, projects, tasks } = useSelector((state) => ({
    me: state.users.me,
    users: state.users.users,
    department: state.departments.departments?.find(
      (department) => department.id === id
    ),
    projects: state.projects.projects,
    tasks: state.tasks.tasks?.filter((task) => task?.department === id),
  }));
  const [projectsMap, setProjectsMap] = React.useState({});
  const today = moment().startOf("day");
  const tomorrow = moment().add(1, "day").startOf("day");

  const onCreateProject = () => {
    const project = {
      name: "New Project",
      priority: "1p",
      status: "active",
      team: [me?.uid],
      asset: `${department?.assets}/cover.png`,
      department: department.id,
    };
    addDoc(collection(database, "projects"), project);
  };
  /**
   * Filtering the projects according to their status.
   */
  React.useEffect(() => {
    const map = {};
    projects
      ?.filter((project) => project.department === id)
      .forEach((project) => {
        if (!map[project?.status]) map[project?.status] = [project];
        else map[project?.status].push(project);
      });
    setProjectsMap(map);
  }, [projects]);

  React.useEffect(() => {
    const source = query(
      collection(database, "projects"),
      where("department", "==", id)
    );
    const stopProjectsSnapshot = onSnapshot(source, (query) => {
      query.docChanges().forEach((change) => {
        if (change.type === "removed") {
          dispatch(deleteProject(change.doc.id));
        } else {
          dispatch(setProject({ id: change.doc.id, ...change.doc.data() }));
        }
      });
    });
    return () => stopProjectsSnapshot();
  }, [dispatch, id]);

  return (
    <main className="flex flex-col pb-10">
      <NavigationBar>
        <Link
          to={`/department/${department?.id}`}
          className="font-normal text-[8px] md:text-xl text-text-color"
        >
          {department?.name}
        </Link>
      </NavigationBar>
      <div className="relative w-full h-[30vh] overflow-hidden">
        <img
          src={`${department?.assets}/cover.png`}
          alt="home-cover"
          className="w-full"
        />
      </div>
      <section className="z-10 flex-1 w-full max-w-[100rem] mx-auto px-5 md:px-10 lg:px-14 space-y-10 md:space-y-20 lg:space-y-32">
        <DepartmentHead
          logo={`${department?.assets}/logo.png`}
          name={department?.name}
        />
        <div className="space-y-4">
          <DropDownSection title="today">
            <TasksTable
              key={0}
              department={department}
              projects={projects}
              tasks={tasks?.filter((task) => {
                if (task?.status === "waiting" || task?.status === "done")
                  return false;
                const do_date = moment(new Date(task?.do_date)).startOf("day");
                return do_date.isSameOrBefore(today);
              })}
            />
          </DropDownSection>
          <DropDownSection title="tomorrow">
            <TasksTable
              key={1}
              department={department}
              projects={projects}
              tasks={tasks?.filter((task) => {
                if (task.status === "waiting" || task?.status === "done")
                  return false;
                const do_date = moment(new Date(task.do_date)).startOf("day");
                return do_date.isSame(tomorrow);
              })}
            />
          </DropDownSection>
          <DropDownSection title="this week">
            <TasksTable
              key={2}
              department={department}
              projects={projects}
              tasks={tasks?.filter((task) => {
                if (task?.status === "waiting" || task?.status === "done")
                  return false;
                const do_date = moment(new Date(task?.do_date)).startOf("day");
                return do_date.isSameOrBefore(moment().endOf("week"));
              })}
            />
          </DropDownSection>
          <DropDownSection title="waiting">
            <TasksTable
              key={3}
              department={department}
              projects={projects}
              tasks={tasks?.filter((task) => task?.status === "waiting")}
            />
          </DropDownSection>
          <DropDownSection title="completed">
            <TasksTable
              key={4}
              department={department}
              projects={projects}
              tasks={tasks?.filter((task) => task?.status === "done")}
            />
          </DropDownSection>
          <DropDownSection title="projects">
            <AppTable>
              <AppTableHead>
                <AppTableTitle
                  title="Aa Project"
                  className="text-[#363636] text-opacity-50"
                />
                <AppTableTitle
                  icon="/assets/svg/group.svg"
                  title="Team"
                  className="text-[#363636] text-opacity-50"
                />
                <AppTableTitle
                  icon="/assets/svg/dropdown.svg"
                  title="Status"
                  className="text-[#363636] text-opacity-50"
                />
                <AppTableTitle
                  icon="/assets/svg/dropdown.svg"
                  title="Priority"
                  className="text-[#363636] text-opacity-50"
                />
                <AppTableTitle
                  icon="/assets/svg/search.svg"
                  title="Completed Actions"
                  className="text-[#363636] text-opacity-50"
                />
                <AppTableTitle
                  icon="/assets/svg/search.svg"
                  title="Total Actions"
                  className="text-[#363636] text-opacity-50"
                />
                <AppTableTitle
                  icon="/assets/svg/functions.svg"
                  title="Progress"
                  className="text-[#363636] text-opacity-50"
                />
              </AppTableHead>
              <AppTableBody>
                {projects
                  ?.filter((project) => project.department === id)
                  .map((project, index) => (
                    <tr key={index}>
                      <AppTableColumn>
                        <p className="font-semibold">{project?.name}</p>
                      </AppTableColumn>
                      <AppTableColumn>
                        <div className="max-w-[15rem] flex gap-1 flex-wrap">
                          {users
                            ?.filter((user) =>
                              project?.team?.includes(user.uid)
                            )
                            .map((user, index) => (
                              <Tag
                                key={index}
                                name={user.customClaims?.job_title}
                              />
                            ))}
                        </div>
                      </AppTableColumn>
                      <AppTableColumn>
                        <Tag name={project?.status} />
                      </AppTableColumn>
                      <AppTableColumn>
                        <Tag name={project?.priority} />
                      </AppTableColumn>
                      <AppTableColumn>
                        <p>{project?.completed}</p>
                      </AppTableColumn>
                      <AppTableColumn>
                        <p>{project?.total}</p>
                      </AppTableColumn>
                      <AppTableColumn>
                        <div className="w-40">
                          <ProgressBar
                            percentage={parseInt(
                              (project?.completed * 100) / project?.total
                            )}
                          />
                        </div>
                      </AppTableColumn>
                    </tr>
                  ))}
              </AppTableBody>
            </AppTable>
          </DropDownSection>
        </div>
        <Calendar
          tasks={tasks.filter((task) => task?.department === department?.id)}
        />
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex items-center space-x-5">
            <img src="/assets/svg/projects.svg" alt="projects" />
            <h2 className="font-semibold text-lg md:text-xl lg:text-2xl">
              Projects
            </h2>
          </div>
          <button
            onClick={onCreateProject}
            className="bg-white hover:bg-black/5 text-black/4 p-2 rounded-lg border-text-color/10 border-[0.5px] font-medium"
          >
            New Project
          </button>
        </div>
        {Object.keys(projectsMap).map((title, index) => (
          <ProjectsSection
            key={index}
            title={title}
            projects={projectsMap[title]}
          />
        ))}
      </section>
    </main>
  );
}
