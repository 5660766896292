import React from "react";
import moment from "moment";
import { Task } from "./Task";

export default function Calendar({ tasks }) {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const daysOfTheWeek = [];
  const fillWeekNames = () => {
    for (let i = 0; i < 7; i++) {
      const dayName = new Date(`${year}-${month}-${i + 1}`).toLocaleDateString(
        "en-US",
        { weekday: "short" }
      );
      daysOfTheWeek.push(dayName);
      if (i === 7) i = 0;
    }
    return daysOfTheWeek;
  };

  const [tasksMap, setTasksMap] = React.useState({});
  React.useEffect(() => {
    const map = {};
    Array.from({ length: moment().daysInMonth() }, (_, index) =>
      String(index + 1)
    ).forEach((dayNumber) => {
      map[dayNumber] = [];
      let index = 0;
      while (index < tasks.length) {
        const due_date = new Date(tasks[index]?.due_date);
        // check whether the task due_date corresponde to the day of the current month
        // if so we will add it the hashmap and remove it from the list to avoid unneccessery count.
        if (
          due_date.getMonth() === new Date(Date.now()).getMonth() &&
          due_date.getDate() === parseInt(dayNumber)
        ) {
          map[dayNumber].push(tasks[index]);
          delete tasks[index];
          continue;
        }
        index++;
      }
    });
    setTasksMap(map);
  }, [tasks]);
  return (
    <div className="space-y-10">
      <div className="space-y-3">
        <h2 className="font-bold text-[#E32434] text-xl md:text-2xl lg:text-3xl">
          Calendar
        </h2>
        <h4 className="font-semibold text-base md:text-lg lg:text-xl">
          {moment().format("MMM yyy")}
        </h4>
      </div>
      <div className="overflow-x-auto">
        <div className="grid grid-cols-7 w-full min-w-max">
          {fillWeekNames().map((day, index) => {
            return (
              <p
                key={index}
                className="pb-4 capitalize text-center text-[#363636] text-opacity-50 text-sm md:text-base lg:text-lg"
              >
                {day}
              </p>
            );
          })}
          {Object.keys(tasksMap).map((dayNumber) => {
            return (
              <div
                key={dayNumber}
                className="w-full max-w-[200px] border-[1px] border-[#363636] border-opacity-50 p-4 text-right text-[#363636] text-opacity-50 space-y-4 max-h-32 overflow-y-scroll"
              >
                <div className="text-right">
                  <p
                    className={`ml-auto w-fit flex items-start justify-center relative text-xs md:text-sm lg:text-base ${
                      moment().date() === parseInt(dayNumber) &&
                      "text-white before:p-2 md:before:p-3 before:bg-[#E32434] before:-rotate-45 before:absolute before:-z-10"
                    }`}
                  >
                    {dayNumber}
                  </p>
                </div>
                {tasksMap[dayNumber]?.filter((task) => task.status !== "done" ).map((task, index) => (
                 <Task key={index} name={task?.action} status={task?.status}/>
                ))}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
