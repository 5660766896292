import React from "react";

export default function DropDownSection({ title, children }) {
  const [hidden, setHidden] = React.useState(true);
  return (
    <section className="border-[1px] border-solid border-[#363636] border-opacity-70 py-2 px-8 space-y-5">
      <button onClick={() => setHidden(!hidden)} className="w-full">
        <div className="w-fit flex items-center gap-x-8">
          <img
            src="/assets/svg/left_arrow.svg"
            alt="left-arrow"
            className={`w-3 transition-all duration-200 ${
              !hidden && "rotate-90"
            }`}
          />
          <h2 className="font-bold capitalize text-lg md:text-xl lg:text-2xl">
            {title}
          </h2>
        </div>
      </button>
      {!hidden && children}
    </section>
  );
}
