import React from "react";

export default function ProgressBar({ percentage }) {
  return (
    <div className="flex items-center gap-x-2 w-full">
      <div className="flex-1 flex rounded-2xl overflow-hidden h-3 bg-[#363636] bg-opacity-10">
        <div
          style={{ width: `${percentage}%` }}
          className="h-3 bg-[#E32434]"
        ></div>
      </div>
      <span>{percentage} %</span>
    </div>
  );
}
