import React from "react";
import { Link } from "react-router-dom";

const Department = ({ department }) => {
  return (
    <Link to={`/department/${department?.id}`}>
      <div className="w-48 md:w-56 rounded-md shadow-lg overflow-hidden text-left cursor-pointer hover:scale-110 transition-all duration-200">
        <img src={`${department?.assets}/cover.png`} alt="department-cover" />
        <p className="bg-white py-3 px-6 font-semibold text-right text-sm md:text-base lg:text-lg">
          {department?.name}
        </p>
      </div>
    </Link>
  );
};
const Departments = ({ departments }) => {
  return (
    <div className="grid gap-5 md:gap-7 lg:gap-10 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-w-fit mx-auto">
      {departments?.map((department, index) => (
        <Department key={index} department={department} />
      ))}
    </div>
  );
};
export default Departments;
