import React from "react";
import { generateLightColor } from "../core/colors";

const Tag = ({ name = null, smallestSize = false }) => {
  const color = generateLightColor(name);
  return (
    <span
      style={{ backgroundColor: color }}
      className={`${
        smallestSize ? "text-[10px]" : "text-xs md:text-sm lg:text-base"
      } w-fit py-[2px] px-3 capitalize`}
    >
      {name}
    </span>
  );
};
export default Tag;
