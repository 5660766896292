import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDoc, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useParams, useNavigate, Link } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import statuses from "../data/status.json";
import priorities from "../data/priority.json";
import TasksTable from "../components/TasksTable";
import CustomSelect, { CustomOption } from "../components/CustomSelect";
import NavigationBar from "../components/NavigationBar";
import { database } from "../firebase";
import { setProject } from "../redux/slices/projects";
import ToolBar from "../components/ToolBar";

export default function Project() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { me, users, currentProject, projects, department, tasks } =
    useSelector((state) => {
      const currentProject = state.projects.projects?.find(
        (project) => project?.id === id
      );
      return {
        me: state.users.me,
        users: state.users.users,
        projects: state.projects.projects,
        department: state.departments.departments.find(
          (department) => department?.id === currentProject?.department
        ),
        tasks: state.tasks.tasks.filter(
          (task) => task?.project === id && task?.status !== "done"
        ),
        currentProject,
      };
    });
  const navigate = useNavigate();
  const onUpdateProject = (updates) => {
    updateDoc(doc(database, "projects", currentProject?.id), updates);
  };

  const onDeleteProject = () => {
    deleteDoc(doc(database, "projects", currentProject?.id)).then(() =>
      navigate(`/department/${department?.id}`)
    );
  };

  React.useEffect(() => {
    const stopProjectSnapshot = onSnapshot(
      doc(database, "projects", id),
      (doc) => dispatch(setProject({ id: doc.id, ...doc.data() }))
    );
    return () => stopProjectSnapshot();
  }, [dispatch, id]);

  return (
    <main className="flex flex-col pb-4 md:pb-10 overflow-hidden">
      <NavigationBar>
        <Link
          to={`/department/${department?.id}`}
          className="font-normal text-[8px] md:text-xl text-text-color"
        >
          {department?.name}
        </Link>
        <Link
          to={`/project/${currentProject?.id}`}
          className="font-normal text-[8px] md:text-xl text-text-color"
        >
          {currentProject?.name}
        </Link>
      </NavigationBar>
      <div className="relative w-full md:h-[30vh] md:overflow-hidden">
        <img src={currentProject?.asset} alt="home-cover" className="w-full" />
      </div>
      <section className="z-10 flex-1 w-full max-w-[100rem] mx-auto px-5 md:px-10 lg:px-14 space-y-10 md:space-y-20 lg:space-y-32">
        <div className="flex items-center justify-around md:justify-between mt-12 md:mt-28">
          <div className="space-y-5">
            <img
              src="/assets/svg/tag.svg"
              alt="project-tag"
              className="-mt-7 md:-mt-10 lg:-mt-16 w-16 md:w-20 lg:w-28 h-16 md:h-20 lg:h-28"
            />
            <input
              type="text"
              className="font-bold text-xl md:text-2xl lg:text-3xl"
              defaultValue={currentProject?.name}
              disabled={!currentProject?.team?.includes(me?.uid)}
              onBlur={(event) => {
                if (event.target.value !== currentProject?.name)
                  onUpdateProject({ name: event.target.value });
              }}
            />
          </div>
          <ToolBar onDelete={() => onDeleteProject(currentProject?.id)} />
        </div>
        <div className="w-full md:w-1/2 space-y-5 md:space-y-10">
          <div className="grid grid-cols-2 md:gap-2">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/dropdown.svg"
                alt="status"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Status</h2>
            </div>
            <CustomSelect
              id="status"
              onSelect={(status) => onUpdateProject({ status })}
            >
              {statuses?.map(({ text: status }, index) => (
                <CustomOption
                  key={index}
                  value={status}
                  selected={status === currentProject?.status}
                >
                  {status}
                </CustomOption>
              ))}
            </CustomSelect>
          </div>
          <div className="grid grid-cols-2 md:gap-2">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/dropdown.svg"
                alt="priority"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Priority</h2>
            </div>
            <CustomSelect
              id="priority"
              onSelect={(priority) => onUpdateProject({ priority })}
            >
              {priorities?.map(({ text: priority }, index) => (
                <CustomOption
                  key={index}
                  value={priority}
                  selected={priority === currentProject?.priority}
                >
                  {priority}
                </CustomOption>
              ))}
            </CustomSelect>
          </div>
          <div className="grid grid-cols-2 md:space-x-6">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/functions.svg"
                alt="progress"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Progress</h2>
            </div>
            <div className="w-full">
              <ProgressBar
                percentage={parseInt(
                  (currentProject?.completed * 100) / currentProject?.total
                    ? (currentProject?.completed * 100) / currentProject?.total
                    : 0
                )}
              />
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="flex items-center gap-x-3 text-[#363636] text-opacity-50">
              <img
                src="/assets/svg/group.svg"
                alt="team"
                className="w-3 md:w-8"
              />
              <h2 className="text-sm md:text-base lg:text-lg">Team</h2>
            </div>
            <CustomSelect
              id="team"
              multiselection
              onSelect={(user) =>
                onUpdateProject({
                  team: Array.isArray(currentProject?.team)
                    ? currentProject?.team?.concat(user)
                    : [user],
                })
              }
              onUnSelect={(uid) => {
                const team = currentProject?.team.filter(
                  (memberId) => memberId !== uid
                );
                if (team?.length > 0) {
                  onUpdateProject({
                    team,
                  });
                }
              }}
            >
              {users?.map((user, index) => (
                <CustomOption
                  key={index}
                  value={user.uid}
                  selected={currentProject?.team?.includes(user.uid)}
                >
                  {user.customClaims?.job_title}
                </CustomOption>
              ))}
            </CustomSelect>
          </div>
        </div>
        <div className="space-y-5 md:space-y-10">
          <div className="flex items-center gap-x-3">
            <img
              src="/assets/svg/table.svg"
              alt="table"
              className="w-4 md:w-10"
            />
            <h2 className="font-bold text-lg md:text-2xl lg:text-3xl">Table</h2>
          </div>
          <TasksTable
            tasks={tasks}
            department={department}
            projects={projects}
            defaults={{ projectId: currentProject?.id }}
          />
        </div>
      </section>
    </main>
  );
}
