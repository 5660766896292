import React from "react";
import Home from "./Home";
import Auth from "./Auth";
import User from "./User";
import Department from "./Department";
import Project from "./Project";
import AuthenticationGuard from "../wrapers/AuthenticationGuard";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Task from "./Task";

export default function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/auth",
      element: <Auth />,
    },
    {
      path: "/user/:id",
      element: <User />,
    },
    {
      path: "/department/:id",
      element: <Department />,
    },
    {
      path: "/project/:id",
      element: <Project />,
    },
    {
      path: "/task/:id",
      element: <Task />,
    },
  ]);
  return (
    <React.StrictMode>
      <AuthenticationGuard>
        <RouterProvider router={router} />
      </AuthenticationGuard>
    </React.StrictMode>
  );
}
