import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  departments: [],
};

const departments = createSlice({
  name: "departments",
  initialState,
  reducers: {
    setDepartment: (state, action) => {
      state.departments.push(action.payload);
    },
    setDepartments: (state, action) => {
      if (Array.isArray(action.payload)) state.departments = action.payload;
    },
  },
});
export const { setDepartment, setDepartments } = departments.actions;
export default departments.reducer;
