import React from "react";
import Tag from "./Tag";


export const Task = ({ name, status }) => {
  return (
    <div className="space-y-1 p-2 text-right bg-white border-[1px] border-gray-400 rounded-md">
      <p className="text-xs text-black">{name}</p>
      <Tag name={status} smallestSize />
    </div> 
  );
};

