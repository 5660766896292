import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  me: null,
  users: [],
};
export const fetchUsers = createAsyncThunk(
  "users/fetch",
  async (_, { getState }) => {
    if (getState().users.users?.length > 0) return;
    const me = getState().users?.me;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/allUsers`, {
        method: "GET",
        headers: {
          authorization: `Bearer ${me?.token}`,
        },
      });
      if (response.status === 200) {
        const { users } = await response.json();
        return users;
      }
    } catch (error) {
      console.error(error);
    }
  }
);
export const editUser = createAsyncThunk("users/edit", async ({ status, job_title }, { getState }) => {
  const me = getState().users?.me;
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/editUser`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${me?.token}`,
        'Content-Type': 'application/json', 
      },
      body: JSON.stringify({ status, job_title }),
    });
    if (response.status === 200) {
      return {status, job_title};
    }
  } catch (error) {
    console.error(error);
  }
});
export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    setMe: (state, action) => {
      state.me = action.payload;
    },
    setUser: (state, action) => {
      state.users.push(action.payload);
    },
    setUsers: (state, action) => {
      if (action.payload) state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      const index = state.users.findIndex((user) => user?.uid === state.me?.uid);
      if (index >= 0) {
        state.users[index].customClaims = {...state.users[index]?.customClaims, ...action.payload};
      }
    });
  },
});
export const { setMe, setUser, setUsers } = users.actions;
export default users.reducer;
